<template>
  <div class="profile-page">
    <n-loader :loading="$var('load')" />
    <c-modal-message v-if="show" title="Вы действительно хотите удалить аккаунт?" :show.sync="show" :agree.sync="isAgree" />
    <div class="container">
      <div class="wrap-title">
        <div class="title">Мой профиль</div>
      </div>

      <div class="user-data">
        <div class="item">
          <div class="label">ФИО:</div>
          <div class="content">
            <div class="text">{{ user.fullName }}</div>
          </div>
        </div>

        <div class="item">
          <div class="label">ИИН:</div>
          <div class="content">
            <div class="text">{{ user.iin }}</div>
          </div>
        </div>

        <div class="item">
          <div class="label">Номер телефона:</div>
          <div class="content">
            <div class="text">{{ user.phone }}</div>
          </div>
        </div>

        <div class="item">
          <div class="label">Email:</div>
          <div class="content">
            <div class="text">{{ user.email }}</div>
          </div>
        </div>

        <div class="item">
          <div class="label">Должность:</div>
          <div class="content">
            <div class="text">{{ user.position }}</div>
          </div>
        </div>

        <div class="item">
          <div class="label">Филиал:</div>
          <div class="content">
            <div class="text" v-if="user.branches?.length > 0"><span v-for="item in user.branches">{{ item.title }}</span></div>
            <div class="text" v-else>Не назначен</div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <div class="profile-button" @click="$router.push({name: 'profile.resetPassword'})">Сменить пароль</div>
        <div class="profile-button danger" @click="showModalDelete">Удалить акаунт</div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from 'assets/logo.png'
import avatar from 'assets/avatar.jpg'
import imgPass from 'assets/password.svg'
import {PushNotifications} from "@capacitor/push-notifications";
import {Capacitor} from "@capacitor/core";
import {FCM} from "@capacitor-community/fcm";

export default {
  name: 'PageIndex',
  data() {
    return {
      logo,
      imgPass,
      avatar,
      show: false,
      isAgree: false,
      user: {},
    }
  },
  watch: {
    isAgree() {
      if (this.isAgree) {
        this.deleteAcc()
      }
    },
  },
  created() {
    this.updateUser()
    this.scrollToTop()
  },
  methods: {
    updateUser() {
      this.$var('load', true)
      if (navigator.onLine && $app.store.getter('app.buffer').length > 0) {
        $app.store.action('app.sendBuffer').finally(() => {
          $app.store.action('app.updateUser').finally(() => {
            this.user = $app.auth.user()
            this.$var('load', false)
          })
        })
      } else {
        $app.store.action('app.updateUser').finally(() => {
          this.user = $app.auth.user()
          this.$var('load', false)
        })
      }
    },
    showModalDelete() {
      this.show = true
      this.isAgree = false
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    deleteAcc() {
      this.$var('load', true)
      $api.auth.deleteAcc($app.auth.user().id).then(() => {
        $app.auth.logout()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.profile-page {
  display: flex;
  flex-direction: column;
  padding-top: 29px;

  .wrap-title{
    text-align: center;
    position: fixed;
    background-color: #F5F5FA;
    left: 0;
    right: 0;
    top: calc( env(safe-area-inset-top) + 100px);
  }

  .title {
    color: black;
    font-size: 16px;
    font-weight: 700;
  }
  .navigation {
    margin-top: 20px;
    .item {
      .left-side {
        display: flex;
        align-items: center;
        font-weight: bold;
        .n-icon {
          margin-right: 10px;
        }
      }
    }
  }

  .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;

    .avatar {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 10px;
    }

    .name{
      font-size: 15px;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .user-data{
    .item{
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }

      .label{
        color: #959494;
        font-weight: 700;
        font-size: 16px;
        padding: 10px;
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 10px;
        height: 40px;
        background-color: #F5F5FA;
        box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(170, 170, 204, 0.25),
        10px 10px 20px rgba(170, 170, 204, 0.5), -10px -10px 20px #FFFFFF;
        border-radius: 16px;
      }
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: underline;
    padding: 0 10px;
    .profile-button {
      color: #686868;
      &.danger {
        color: #FF4B33;
      }
    }
  }
}
</style>
